.header {
    margin-top: 40px;
    padding-inline: 175px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 100;
}

.header_nav_list {
    display: flex;
    gap: 66px;
    padding: 12px 21px;
    background-color: #333333;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
}

.sticky_nav {
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}

.sticky_btn,
.sticky_logo {
    opacity: 0;
    transition: 0.5s;
}

.header_logo {
    transition: 0.5s;
    height: 40px;
}

.herder_nav_list_item a {
    font-family: 'TTTravels-Medium', sans-serif;
    font-weight: 500;
    font-size: 16px;
    color: white;
}

.header_btn a {
    background-color: #577AE4;
    padding: 12px 21px;
    font-family: 'TTTravels-Medium', sans-serif;
    border-radius: 30px;
    color: white;
    transition: 0.5s;
}

.bm-item {
    display: inline-block;
    text-decoration: none;
    margin-bottom: 10px;
    color: #d1d1d1;
    transition: color 0.2s;
}

.bm-item:hover {
    color: white;
}

.bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    right: 11px;
    top: 15px;
}

.bm-burger-bars {
    background: #373a47;
    height: 16% !important;
    border-radius: 10px;
}

.bm-cross-button {
    height: 34px !important;
    width: 34px !important;
}

.bm-cross {
    background: #bdc3c7;
    height: 30px !important;
}

.bm-menu {
    background-color: #333333;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
}

.bm-morph-shape {
    fill: #080a11;
}

.bm-item-list {
    color: #ffffff;
    font-family: 'TTTravels-Medium', sans-serif;
}

.bm-overlay {
    position: fixed;
    z-index: 1000;
    width: 100%;
    top: 0px;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    opacity: 1;
    transition: opacity 0.3s;
}

.bm-menu-wrap {
    position: fixed;
    right: 0px;
    z-index: 1100;
    width: 170px !important;
    height: 100%;
    transition: 0.5s;
    top: 0px;
}

#react-burger-menu-btn {
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: 1;
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    border: none;
    font-size: 0px;
    background-color: #333333;
    cursor: pointer;
}

@media (min-width: 1024px) and (max-width: 1439px) {
    .header {
        padding-inline: 40px;
    }

    .header_nav_list {
        gap: 45px;
    }

    .bm-menu-wrap,
    .bm-overlay,
    .bm-burger-button {
        display: none;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .header {
        padding-inline: 20px;
        margin-top: 20px;
    }

    .header_nav_list {
        gap: 15px;
        padding: 10px 20px;
    }

    .header_btn {
        display: none;
    }

    .herder_nav_list_item a {
        font-size: 14px;
    }

    .bm-menu-wrap,
    .bm-overlay,
    .bm-burger-button {
        display: none;
    }
}

@media (min-width: 425px) and (max-width: 767px) {
    .header {
        padding-inline: 10px;
        margin-top: 10px;
        position: initial;
    }

    .sticky_btn,
    .sticky_logo {
        opacity: 1;
        transition: 0.5s;
    }

    .header_nav_list {
        gap: 15px;
        padding: 10px 20px;
        display: none;
    }

    .header_btn {
        display: none;
    }

    .herder_nav_list_item a {
        font-size: 14px;
    }

}

@media (min-width: 375px) and (max-width: 424px) {
    .header {
        padding-inline: 10px;
        margin-top: 10px;
        position: initial;
    }

    .sticky_btn,
    .sticky_logo {
        opacity: 1;
        transition: 0.5s;
    }

    .header_nav_list {
        gap: 15px;
        padding: 10px 20px;
        display: none;
    }

    .header_btn {
        display: none;
    }

    .herder_nav_list_item a {
        font-size: 14px;
    }
}

@media (min-width: 320px) and (max-width: 374px) {
    .header {
        padding-inline: 10px;
        margin-top: 10px;
        position: initial;
    }

    .sticky_btn,
    .sticky_logo {
        opacity: 1;
        transition: 0.5s;
    }

    .header_nav_list {
        gap: 15px;
        padding: 10px 20px;
        display: none;
    }

    .header_btn {
        display: none;
    }

    .herder_nav_list_item a {
        font-size: 14px;
    }
}

@media (min-width: 1440px) {

    .bm-menu-wrap,
    .bm-overlay,
    .bm-burger-button {
        display: none;
    }
}