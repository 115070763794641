.contact {
    margin-inline: 80px;
    margin-top: 45px;
    display: flex;
    justify-content: space-between;
}

.contact_title {
    display: flex;
    flex-direction: column;
}

.contact_title h1 {
    font-size: 32px;
    font-family: 'TTTravels-BlackItalic', sans-serif;
    color: #333333;
}

.contact_title strong {
    font-size: 32px;
    font-family: 'TTTravels-BlackItalic', sans-serif;
    color: #4169E1;
}

.dot {
    margin-top: 16px;
    display: flex;
    gap: 4px;
    align-items: center;
}

.dot3 {
    width: 11px;
    height: 11px;
    background-color: #4169E1;
    border-radius: 50%;
}

.dot4,
.dot2 {
    width: 9px;
    height: 9px;
    background-color: #4169E170;
    border-radius: 50%;
}

.dot5,
.dot1 {
    width: 7px;
    height: 7px;
    background-color: #4169E150;
    border-radius: 50%;
}

.contact_second_block {
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
}

.contact_text_block {
    display: flex;
    flex-direction: column;
}

.contact_text_block h2 {
    width: 455px;
    font-size: 23px;
    font-weight: 500;
    font-family: 'TTTravels-Bold', sans-serif;
    color: #333333;
}

.contact_text_block p {
    margin-top: 20px;
    width: 473px;
    font-size: 16px;
    font-weight: 400;
    font-family: 'TTTravels-Regular', sans-serif;
    color: #333333;
}

.contact_form {
    margin-top: 24px;
}

.contact_form form {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.contact_form input {
    width: 296px;
    height: 36px;
    padding-left: 10px;
    border-radius: 24px;
    font-size: 20px;
    font-weight: 500;
    font-family: 'TTTravels-Medium', sans-serif;
    outline: none;
    border: 1px solid black;
}

.contact_form input:focus {
    outline: none;
}

.contact_form button {
    color: white;
    font-size: 20px;
    font-weight: 500;
    font-family: 'TTTravels-Medium', sans-serif;
    background-color: #4169E1;
    border-radius: 30px;
    width: 228px;
    height: 54px;
    border: none;
}


@media (min-width: 1024px) and (max-width: 1439px) {
    .contact {
        margin-inline: 40px;
    }

    .contact_img_block img {
        width: 500px;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .contact {
        margin-inline: 20px;
        flex-direction: column;
    }

    .contact_img_block img {
        width: 500px;
    }

    .contact_text_block h2 {
        width: 100%;
    }

    .contact_text_block p {
        width: 100%;
    }

    .contact_img_block {
        margin-top: 20px;
        display: flex;
        justify-content: center;
    }
}

@media (min-width: 425px) and (max-width: 767px) {
    .contact {
        margin-inline: 20px;
        flex-direction: column;
    }

    .contact_img_block img {
        width: 300px;
    }

    .contact_text_block h2 {
        width: 100%;
    }

    .contact_text_block p {
        width: 100%;
    }

    .contact_img_block {
        margin-top: 20px;
        display: flex;
        justify-content: center;
    }
}

@media (min-width: 375px) and (max-width: 424px) {
    .contact {
        margin-inline: 20px;
        flex-direction: column;
    }

    .contact_img_block img {
        width: 300px;
    }

    .contact_text_block h2 {
        width: 100%;
    }

    .contact_text_block p {
        width: 100%;
    }

    .contact_img_block {
        margin-top: 20px;
        display: flex;
        justify-content: center;
    }
}

@media (min-width: 320px) and (max-width: 374px) {
    .contact {
        margin-inline: 20px;
        flex-direction: column;
    }

    .contact_img_block img {
        width: 300px;
    }

    .contact_text_block h2 {
        width: 100%;
        font-size: 14px;
    }

    .contact_text_block p {
        width: 100%;
        font-size: 14px;
    }

    .contact_img_block {
        margin-top: 20px;
        display: flex;
        justify-content: center;
    }

    .contact_form input {
        width: max-content;
        font-size: 18px;
    }

    .contact_form button {
        font-size: 16px;
        width: 166px;
        height: 32px;
    }

    .contact_title h1 {
        font-size: 24px;
    }

    .contact_title strong {
        font-size: 24px;
    }
}