.footer {
    margin-top: 40px;
    background-color: #4169E1;
    height: 325px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.footer_up {
    margin-inline: 80px;
    padding-top: 37px;
    display: flex;
    justify-content: space-between;
}

.footer_up_left {
    display: flex;
    gap: 45px;
}

.footer_loc {
    display: flex;
    flex-direction: column;
    gap: 80px;
    width: 270px;
}

.footer_loc span {
    font-size: 24px;
    font-weight: 500;
    color: white;
    font-family: 'TTTravels-Medium', sans-serif;
}

.footer_loc a {
    font-size: 16px;
    color: white;
    font-weight: 500;
    font-family: 'TTTravels-Medium', sans-serif;
}


.footer_number_email {
    gap: 6px;
    display: flex;
    flex-direction: column;
}

.footer_up_right {
    display: flex;
    gap: 90px;
}

.footer_nav {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.footer_nav a {
    font-size: 16px;
    color: white;
    font-weight: 500;
    font-family: 'TTTravels-Medium', sans-serif;
}

.footer_btn a {
    width: 52px;
    height: 52px;
    background-color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer_down {
    text-align: center;
    padding-bottom: 5px;
}

.footer_down span {
    text-align: center;
    font-size: 12px;
    color: white;
    font-weight: 400;
    font-family: 'TTTravels-Regular', sans-serif;
}


@media (min-width: 768px) and (max-width: 1023px) {
    .footer {
        margin-top: 20px;
        height: 240px;
    }

    .footer_up {
        margin-inline: 20px;
        padding-top: 37px;
        display: flex;
        justify-content: space-between;
    }

    .footer_loc span {
        font-size: 16px;
    }

    .footer_up_right {
        display: flex;
        gap: 40px;
    }

    .footer_loc {
        gap: 50px;
    }
}

@media (min-width: 425px) and (max-width: 767px) {
    .footer {
        margin-top: 20px;
        height: 100%;
        gap: 20px;
    }

    .footer_up {
        flex-direction: column;
        gap: 20px;
    }

    .footer_loc span {
        font-size: 16px;
    }

    .footer_up_right {
        display: flex;
        gap: 40px;
        justify-content: center;
        align-items: center;
    }

    .footer_loc {
        gap: 50px;
    }

    .footer_up_left {
        display: flex;
        gap: 45px;
        flex-direction: column;
        align-items: center;
    }
}

@media (min-width: 375px) and (max-width: 424px) {
    .footer {
        margin-top: 20px;
        height: 100%;
        gap: 20px;
    }

    .footer_up {
        flex-direction: column;
        gap: 20px;
    }

    .footer_loc span {
        font-size: 16px;
    }

    .footer_up_right {
        display: flex;
        gap: 40px;
        justify-content: center;
        align-items: center;
    }

    .footer_loc {
        gap: 50px;
    }

    .footer_up_left {
        display: flex;
        gap: 45px;
        flex-direction: column;
        align-items: center;
    }
}

@media (min-width: 320px) and (max-width: 374px) {
    .footer {
        margin-top: 20px;
        height: 100%;
        gap: 20px;
    }

    .footer_up {
        flex-direction: column;
        gap: 20px;
    }

    .footer_loc span {
        font-size: 16px;
    }

    .footer_up_right {
        display: flex;
        gap: 40px;
        justify-content: center;
        align-items: center;
    }

    .footer_loc {
        gap: 50px;
    }

    .footer_up_left {
        display: flex;
        gap: 45px;
        flex-direction: column;
        align-items: center;
    }
}