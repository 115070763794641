.product {
	margin-inline: 80px;
	margin-top: 45px;
}

.product_title {
	display: flex;
	flex-direction: column;
}

.product_title h1 {
	font-size: 32px;
	font-family: 'TTTravels-BlackItalic', sans-serif;
	color: #333333;
}

.product_title strong {
	font-size: 32px;
	font-family: 'TTTravels-BlackItalic', sans-serif;
	color: #4169E1;
}

.dot {
	margin-top: 16px;
	display: flex;
	gap: 4px;
	align-items: center;
}

.dot3 {
	width: 11px;
	height: 11px;
	background-color: #4169E1;
	border-radius: 50%;
}

.dot4,
.dot2 {
	width: 9px;
	height: 9px;
	background-color: #4169E170;
	border-radius: 50%;
}

.dot5,
.dot1 {
	width: 7px;
	height: 7px;
	background-color: #4169E150;
	border-radius: 50%;
}

.product_second_block {
	margin-top: 16px;
	display: flex;
	flex-direction: column;
}

.product_card_block {
	display: flex;
	justify-content: space-between;
	margin-top: 40px;
}

.product_card {
	width: 305px;
	display: flex;
	flex-direction: column;
	gap: 11px;
}

.product_card p {
	font-size: 16px;
	font-family: 'TTTravels-DemiBold', sans-serif;
	color: #333333;
}

.product_text_block {
	display: flex;
	flex-direction: column;
}

.product_text_block h2 {
	width: 523px;
	font-size: 16px;
	font-weight: 500;
	font-family: 'TTTravels-Medium', sans-serif;
	color: #333333;
}

@media (min-width: 1024px) and (max-width: 1439px) {
	.product {
		margin-inline: 40px;
	}

	.product_card {
		width: 220px;
	}

	.product_card p {
		font-size: 14px;
	}
}

@media (min-width: 768px) and (max-width: 1023px) {
	.product {
		margin-inline: 20px;
	}

	.product_card {
		width: 340px;
	}

	.product_card p {
		font-size: 14px;
	}

	.product_text_block h2 {
		width: 100%;
	}

	.product_card_block {
		margin-top: 40px;
		flex-wrap: wrap;
		gap: 25px;
	}
}

@media (min-width: 425px) and (max-width: 767px) {
	.product {
		margin-inline: 10px;
	}

	.product_card p {
		font-size: 14px;
	}

	.product_text_block h2 {
		width: 100%;
	}

	.product_card {
		width: max-content;
	}

	.product_card_block {
		margin-top: 40px;
		flex-wrap: wrap;
		gap: 25px;
	}
}

@media (min-width: 375px) and (max-width: 424px) {
	.product {
		margin-inline: 10px;
	}

	.product_card p {
		font-size: 14px;
	}

	.product_text_block h2 {
		width: 100%;
	}

	.product_card {
		width: max-content;
	}

	.product_card_block {
		margin-top: 40px;
		flex-wrap: wrap;
		gap: 25px;
	}
}

@media (min-width: 320px) and (max-width: 374px) {
	.product {
		margin-inline: 10px;
	}

	.product_card p {
		font-size: 14px;
	}

	.product_text_block h2 {
		width: 100%;
		font-size: 14px;
	}

	.product_card {
		width: max-content;
	}

	.product_card_block {
		margin-top: 40px;
		flex-wrap: wrap;
		gap: 25px;
	}

	.product_title strong {
		font-size: 24px;
	}

	.product_title h1 {
		font-size: 24px;
	}
}