.hero_text {
    margin-inline: 80px;
    margin-top: 66px;
}

.hero_text_first_block {
    width: 100%;
    display: flex;
    flex-direction: column;
}


.hero_text_first_block h1 {
    text-align: end;
    font-size: 78px;
    font-family: 'TTTravels-BlackItalic', sans-serif;
    color: #333333;
    line-height: 70px;
}

.hero_text_first_block span {
    text-align: justify;
    line-height: 20px;
    font-size: 33.9px;
    font-family: 'TTTravels-DemiBold', sans-serif;
    color: #333333;
}


.hero_text_second_block {
    display: flex;
    gap: 20px;
    padding-top: 5px;
}

.hero_text_second_block a {
    background-color: #577AE4;
    min-width: 305px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 12px 21px;
    font-family: 'TTTravels-Medium', sans-serif;
    border-radius: 30px;
    color: white;
}

.hero_text_second_block p {
    text-align: justify;
    font-size: 16px;
    font-family: 'TTTravels-Medium', sans-serif;
    color: #333333;
}

.hero_arrow_block {
    position: relative;
}

.hero_arrow_block_blocks {
    position: absolute;
    top: 136px;
    left: 192px;
    display: flex;
    align-items: flex-end;
    gap: 20px;
}

.blocks1 {
    width: 197px;
    height: 73px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background: rgb(65, 105, 225);
    background: linear-gradient(180deg, rgba(65, 105, 225, 1) 0%, rgba(153, 174, 239, 1) 63%, rgba(196, 207, 246, 1) 83%, rgba(248, 248, 255, 1) 100%);
}

.blocks2 {
    width: 197px;
    height: 137px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background: rgb(65, 105, 225);
    background: linear-gradient(180deg, rgba(65, 105, 225, 1) 0%, rgba(153, 174, 239, 1) 63%, rgba(196, 207, 246, 1) 83%, rgba(248, 248, 255, 1) 100%);
}

.blocks3 {
    width: 197px;
    height: 208px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background: rgb(65, 105, 225);
    background: linear-gradient(180deg, rgba(65, 105, 225, 1) 0%, rgba(153, 174, 239, 1) 63%, rgba(196, 207, 246, 1) 83%, rgba(248, 248, 255, 1) 100%);
}

.blocks4 {
    width: 197px;
    height: 256px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background: rgb(65, 105, 225);
    background: linear-gradient(180deg, rgba(65, 105, 225, 1) 0%, rgba(153, 174, 239, 1) 63%, rgba(196, 207, 246, 1) 83%, rgba(248, 248, 255, 1) 100%);
}

.blocks5 {
    width: 197px;
    height: 330px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background: rgb(65, 105, 225);
    background: linear-gradient(180deg, rgba(65, 105, 225, 1) 0%, rgba(153, 174, 239, 1) 63%, rgba(196, 207, 246, 1) 83%, rgba(248, 248, 255, 1) 100%);
}

.hero_third {
    margin-top: 60px;
    display: flex;
    justify-content: center;
    gap: 36px;
}

.hero_third_block {
    padding: 8px 120px;
    border: 1px solid #333333;
    border-radius: 60px;
}

.hero_third_block h3 {
    font-family: 'TTTravels-ExtraBoldItalic', sans-serif;
    font-size: 40px;
    color: #333333;
    text-align: center;
}

.hero_third_block span {
    font-size: 16px;
    font-family: 'TTTravels-Medium', sans-serif;
    color: #333333;
    text-align: center;
}

.hero_third_mobile {
    display: none;
}

@media (min-width: 1024px) and (max-width: 1439px) {
    .hero_text {
        margin-inline: 40px;
    }

    .hero_text_first_block h1 {
        font-size: 60px;
        line-height: 48px;
    }

    .hero_text_first_block span {
        font-size: 24.9px;
    }

    .hero_text_second_block a {
        min-width: 175px;
        height: 30px;
    }

    .hero_text_second_block p {
        font-size: 14px;
    }

    .hero_arrow_block img {
        width: 90vw;
    }

    .hero_arrow_block_blocks {
        top: 90px;
        left: 130px;
        gap: 15px;
    }

    .blocks1,
    .blocks2,
    .blocks3,
    .blocks4,
    .blocks5 {
        width: 140px;
    }

    .blocks2 {
        height: 120px;
    }

    .blocks3 {
        height: 170px;
    }

    .blocks4 {
        height: 200px;
    }

    .blocks5 {
        height: 265px;
    }

    .hero_third {
        justify-content: center;
        gap: 10px;
    }

    .hero_third_block {
        padding: 5px 85px;
    }

    .hero_third_block h3 {
        font-size: 24px;

    }

    .hero_third_block span {
        font-size: 14px;
    }

    .hero_third_mobile {
        display: none;
    }
}


@media (min-width: 768px) and (max-width: 1023px) {
    .hero_text {
        margin-inline: 20px;
        margin-top: 40px;
    }

    .hero_text_first_block h1 {
        font-size: 45px;
        line-height: 35px;
    }

    .hero_text_first_block span {
        font-size: 18.9px;
    }

    .hero_text_second_block a {
        min-width: 175px;
        height: 30px;
    }

    .hero_text_second_block p {
        font-size: 14px;
    }

    .hero_arrow_block img {
        width: 90vw;
    }

    .hero_arrow_block_blocks {
        top: 68px;
        left: 96px;
        gap: 15px;
    }

    .blocks1,
    .blocks2,
    .blocks3,
    .blocks4,
    .blocks5 {
        width: 100px;
    }

    .blocks1 {
        height: 50px;
    }

    .blocks2 {
        height: 80px;
    }

    .blocks3 {
        height: 115px;
    }

    .blocks4 {
        height: 150px;
    }

    .blocks5 {
        height: 190px;
    }

    .hero_third {
        margin-top: 30px;
        justify-content: center;
        gap: 10px;
    }

    .hero_third_block {
        padding: 5px 55px;
    }

    .hero_third_block h3 {
        font-size: 20px;
    }

    .hero_third_block span {
        font-size: 14px;
    }
}

@media (min-width: 425px) and (max-width: 767px) {
    .hero_text {
        margin-inline: 10px;
        margin-top: 40px;
    }

    .hero_text_first_block h1 {
        font-size: 25px;
        line-height: 20px;
    }

    .hero_text_first_block span {
        font-size: 17.9px;
    }

    .hero_text_second_block a {
        max-width: 160px;
        min-width: 100px;
        height: 30px;
    }

    .hero_text_second_block p {
        font-size: 14px;
        width: 230px;
    }

    .hero_arrow_block img {
        width: 90vw;
    }

    .hero_arrow_block_blocks {
        top: 40px;
        left: 55px;
        gap: 10px;
    }

    .blocks1,
    .blocks2,
    .blocks3,
    .blocks4,
    .blocks5 {
        width: 55px;
    }

    .blocks1 {
        height: 45px;
    }

    .blocks2 {
        height: 60px;
    }

    .blocks3 {
        height: 80px;
    }

    .blocks4 {
        height: 95px;
    }

    .blocks5 {
        height: 120px
    }

    .hero_third {
        display: none;
    }

    .hero_text_second_block {
        display: flex;
        gap: 20px;
        padding-top: 5px;
        flex-direction: column-reverse;
    }


    .hero_text_second_mobile_block {
        display: flex;
        justify-content: space-between;
    }

    .hero_third_mobile {
        display: flex;
        gap: 10px;
        flex-direction: column;
    }

    .hero_third_block_mobile {
        padding: 1px 55px;
        border: 1px solid #333333;
        border-radius: 60px;
        display: flex;
        flex-direction: column;
    }

    .hero_third_block_mobile h3 {
        font-family: 'TTTravels-ExtraBoldItalic', sans-serif;
        font-size: 16px;
        color: #333333;
        text-align: center;
    }

    .hero_third_block_mobile span {
        font-size: 6px;
        font-family: 'TTTravels-Medium', sans-serif;
        color: #333333;
        text-align: center;
    }
}

@media (min-width: 375px) and (max-width: 424px) {
    .hero_text {
        margin-inline: 10px;
        margin-top: 40px;
    }

    .hero_text_first_block h1 {
        font-size: 25px;
        line-height: 20px;
    }

    .hero_text_first_block span {
        font-size: 17.9px;
    }

    .hero_text_second_block a {
        max-width: 160px;
        min-width: 100px;
        height: 30px;
    }

    .hero_text_second_block p {
        font-size: 14px;
        width: 230px;
    }

    .hero_arrow_block img {
        width: 95vw;
    }

    .hero_arrow_block_blocks {
        top: 37px;
        left: 53px;
        gap: 10px;
    }

    .blocks1,
    .blocks2,
    .blocks3,
    .blocks4,
    .blocks5 {
        width: 49px;
    }

    .blocks1 {
        height: 45px;
    }

    .blocks2 {
        height: 60px;
    }

    .blocks3 {
        height: 80px;
    }

    .blocks4 {
        height: 95px;
    }

    .blocks5 {
        height: 120px
    }

    .hero_third {
        display: none;
    }

    .hero_text_second_block {
        display: flex;
        gap: 20px;
        padding-top: 5px;
        flex-direction: column-reverse;
    }


    .hero_text_second_mobile_block {
        display: flex;
        justify-content: space-between;
    }

    .hero_third_mobile {
        display: flex;
        gap: 10px;
        flex-direction: column;
    }

    .hero_third_block_mobile {
        padding: 0px 30px;
        border: 1px solid #333333;
        border-radius: 60px;
        display: flex;
        flex-direction: column;
    }

    .hero_third_block_mobile h3 {
        font-family: 'TTTravels-ExtraBoldItalic', sans-serif;
        font-size: 16px;
        color: #333333;
        text-align: center;
    }

    .hero_third_block_mobile span {
        font-size: 6px;
        font-family: 'TTTravels-Medium', sans-serif;
        color: #333333;
        text-align: center;
    }
}

@media (min-width: 320px) and (max-width: 374px) {
    .hero_text {
        margin-inline: 10px;
        margin-top: 40px;
    }

    .hero_text_first_block h1 {
        font-size: 25px;
        line-height: 20px;
    }

    .hero_text_first_block span {
        font-size: 17.9px;
    }

    .hero_text_second_block a {
        max-width: 160px;
        min-width: 100px;
        height: 30px;
    }

    .hero_text_second_block p {
        font-size: 12px;
        width: 180px;
    }

    .hero_arrow_block img {
        width: 95vw;
    }

    .hero_arrow_block_blocks {
        top: 33px;
        left: 45px;
        gap: 10px;
    }

    .blocks1,
    .blocks2,
    .blocks3,
    .blocks4,
    .blocks5 {
        width: 41px;
    }

    .blocks1 {
        height: 45px;
    }

    .blocks2 {
        height: 60px;
    }

    .blocks3 {
        height: 80px;
    }

    .blocks4 {
        height: 95px;
    }

    .blocks5 {
        height: 110px;
    }

    .hero_third {
        display: none;
    }

    .hero_text_second_block {
        display: flex;
        gap: 20px;
        padding-top: 5px;
        flex-direction: column-reverse;
    }


    .hero_text_second_mobile_block {
        display: flex;
        justify-content: space-between;
    }

    .hero_third_mobile {
        display: flex;
        gap: 10px;
        flex-direction: column;
    }

    .hero_third_block_mobile {
        padding: 0px 30px;
        border: 1px solid #333333;
        border-radius: 60px;
        display: flex;
        flex-direction: column;
    }

    .hero_third_block_mobile h3 {
        font-family: 'TTTravels-ExtraBoldItalic', sans-serif;
        font-size: 16px;
        color: #333333;
        text-align: center;
    }

    .hero_third_block_mobile span {
        font-size: 6px;
        font-family: 'TTTravels-Medium', sans-serif;
        color: #333333;
        text-align: center;
    }
}

@media (min-width: 2560px) {
    .hero_arrow_block_blocks {
        position: absolute;
        top: 136px;
        left: 192px;
        display: flex;
        align-items: flex-end;
        gap: 20px;
    }
}