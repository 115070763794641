* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  /* border: 1px solid red; */
}

a {
  text-decoration: none;
}

body {
  background-color: #F8F8FF;
}

@media (min-width: 1024px) and (max-width: 1439px) {}

@media (min-width: 768px) and (max-width: 1023px) {}

@media (min-width: 425px) and (max-width: 767px) {}

@media (min-width: 375px) and (max-width: 424px) {}

@media (min-width: 320px) and (max-width: 374px) {}


@font-face {
  font-family: 'TTTravels-BlackItalic';
  src: url('./source/fonts/TTTravels-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'TTTravels-Bold';
  src: url('./source/fonts/TTTravels-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'TTTravels-DemiBold';
  src: url('./source/fonts/TTTravels-DemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'TTTravels-ExtraBoldItalic';
  src: url('./source/fonts/TTTravels-ExtraBoldItalic.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'TTTravels-Medium';
  src: url('./source/fonts/TTTravels-Medium.woff') format('woff');
  font-weight: 500;
}

@font-face {
  font-family: 'TTTravels-Regular';
  src: url('./source/fonts/TTTravels-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'TTTravels-Thin';
  src: url('./source/fonts/TTTravels-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}