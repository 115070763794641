 .service {
 	margin-inline: 80px;
 	margin-top: 45px;
 }

 .service_title {
 	display: flex;
 	flex-direction: column;
 }

 .service_title h1 {
 	font-size: 32px;
 	font-family: 'TTTravels-BlackItalic', sans-serif;
 	color: #333333;
 }

 .service_title strong {
 	font-size: 32px;
 	font-family: 'TTTravels-BlackItalic', sans-serif;
 	color: #4169E1;
 }

 .dot {
 	margin-top: 16px;
 	display: flex;
 	gap: 4px;
 	align-items: center;
 }

 .dot3 {
 	width: 11px;
 	height: 11px;
 	background-color: #4169E1;
 	border-radius: 50%;
 }

 .dot4,
 .dot2 {
 	width: 9px;
 	height: 9px;
 	background-color: #4169E170;
 	border-radius: 50%;
 }

 .dot5,
 .dot1 {
 	width: 7px;
 	height: 7px;
 	background-color: #4169E150;
 	border-radius: 50%;
 }

 .service_second_block {
 	margin-top: 16px;
 	display: flex;
 	justify-content: space-between;
 }

 .service_text_block {
 	display: flex;
 	flex-direction: column;
 }

 .service_text_block h2 {
 	width: 722px;
 	font-size: 16px;
 	font-weight: 500;
 	font-family: 'TTTravels-Medium', sans-serif;
 	color: #333333;
 }

 .service_list {
 	padding-top: 54px;
 	padding-left: 80px;
 }

 .service_list h3 {
 	font-size: 16px;
 	font-weight: 500;
 	font-family: 'TTTravels-Medium', sans-serif;
 	color: #333333;
 }

 .service_list ul {
 	padding-left: 60px;
 	padding-top: 15px;
 	display: flex;
 	flex-direction: column;
 	gap: 18px;
 }

 .service_list li {
 	position: relative;
 	font-size: 16px;
 	font-weight: 500;
 	font-family: 'TTTravels-Medium', sans-serif;
 	color: #333333;
 }

 .service_list li::before {
 	content: '';
 	position: absolute;
 	left: -10px;
 	width: 4px;
 	height: 19px;
 	background-color: #4169E1;
 }

 .service_advantages_list_block {
 	margin-top: 60px;
 	display: flex;
 	justify-content: space-between;
 }

 .service_advantages_list_block_left {
 	display: flex;
 	flex-direction: column;
 	gap: 90px;
 }

 .service_advantages_list_item {
 	display: flex;
 	gap: 16px;
 	width: 600px;
 }

 .service_advantages_list_item img {
 	width: 44px;
 	height: 44px;
 }

 .service_advantages_list_text span {
 	font-size: 18px;
 	font-weight: 500;
 	font-family: 'TTTravels-Medium', sans-serif;
 	color: #333333;
 }

 .service_advantages_list_text p {
 	font-size: 16px;
 	font-weight: 500;
 	font-family: 'TTTravels-Medium', sans-serif;
 	color: #333333;
 }


 @media (min-width: 1024px) and (max-width: 1439px) {
 	.service {
 		margin-inline: 40px;
 	}

 	.service_text_block h2 {
 		width: 460px;
 	}

 	.service_list {
 		padding-top: 25px;
 		padding-left: 20px;
 	}

 	.service_list ul {
 		padding-left: 30px;
 		padding-top: 15px;
 	}

 	.service_advantages_list_block {
 		flex-direction: column;
 		align-items: center;
 		gap: 90px;
 	}
 }

 @media (min-width: 768px) and (max-width: 1023px) {
 	.service {
 		margin-inline: 20px;
 	}

 	.service_text_block h2 {
 		width: 100%;
 	}

 	.service_list {
 		padding-top: 30px;
 		padding-left: 40px;
 	}

 	.service_second_block {
 		flex-direction: column;
 	}

 	.service_advantages_list_block {
 		flex-direction: column;
 		align-items: center;
 		gap: 40px;
 	}

 	.service_advantages_list_block_left {
 		gap: 40px;
 	}

 	.service_img_block {
 		display: flex;
 		justify-content: center;
 	}
 }

 @media (min-width: 425px) and (max-width: 767px) {
 	.service {
 		margin-inline: 20px;
 	}

 	.service_text_block h2 {
 		width: 100%;
 	}

 	.service_list {
 		padding-top: 25px;
 		padding-left: 0px;
 	}

 	.service_list ul {
 		padding-left: 30px;
 	}

 	.service_second_block {
 		flex-direction: column;
 	}

 	.service_advantages_list_block {
 		flex-direction: column;
 		align-items: center;
 		gap: 40px;
 	}

 	.service_advantages_list_block_left {
 		gap: 40px;
 	}

 	.service_img_block {
 		display: flex;
 		justify-content: center;
 	}

 	.service_advantages_list_item {
 		display: flex;
 		gap: 16px;
 		width: 300px;
 	}

 	.service_img_block img {
 		width: 300px;
 	}
 }

 @media (min-width: 375px) and (max-width: 424px) {
 	.service {
 		margin-inline: 20px;
 	}

 	.service_text_block h2 {
 		width: 100%;
 	}

 	.service_list {
 		padding-top: 25px;
 		padding-left: 0px;
 	}

 	.service_list ul {
 		padding-left: 30px;
 	}

 	.service_second_block {
 		flex-direction: column;
 	}

 	.service_advantages_list_block {
 		flex-direction: column;
 		align-items: center;
 		gap: 40px;
 	}

 	.service_advantages_list_block_left {
 		gap: 40px;
 	}

 	.service_img_block {
 		display: flex;
 		justify-content: center;
 	}

 	.service_advantages_list_item {
 		display: flex;
 		gap: 16px;
 		width: 300px;
 	}

 	.service_img_block img {
 		width: 300px;
 	}
 }

 @media (min-width: 320px) and (max-width: 374px) {
 	.service {
 		margin-inline: 20px;
 	}

 	.service_text_block h2 {
 		width: 100%;
 		font-size: 14px;
 	}

 	.service_list h3 {
 		font-size: 14px;
 	}

 	.service_list li {
 		font-size: 12px;
 	}

 	.service_advantages_list_text span {
 		font-size: 14px;
 	}

 	.service_advantages_list_text p {
 		font-size: 12px;
 	}

 	.service_list {
 		padding-top: 25px;
 		padding-left: 0px;
 	}

 	.service_list ul {
 		padding-left: 30px;
 	}

 	.service_second_block {
 		flex-direction: column;
 	}

 	.service_advantages_list_block {
 		flex-direction: column;
 		align-items: center;
 		gap: 40px;
 	}

 	.service_advantages_list_block_left {
 		gap: 40px;
 	}

 	.service_img_block {
 		display: flex;
 		justify-content: center;
 	}

 	.service_advantages_list_item {
 		display: flex;
 		gap: 16px;
 		width: 300px;
 	}

 	.service_img_block img {
 		width: 300px;
 	}

 	.service_title strong {
 		font-size: 24px;
 	}

 	.service_title h1 {
 		font-size: 24px;
 	}
 }